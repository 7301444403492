/* eslint-disable no-unused-vars */
import React from "react"
import { graphql } from "gatsby"
import { nanoid } from "nanoid"
import { ClientSearch, PageInformation } from "trv-ebus-tcom-reactcomponents"
import filterPageContent, {
  getComponent,
  flattenObject,
} from "../utilities/filterPageContent"
import { arrayOf, oneOf, object, shape, string } from "prop-types"
import "../styles/main.scss"
import BaseLayout from "../templates/baseLayout"
import getSitewideMessages from "../utilities/getSiteWideMessages"

export const query = graphql`
  query {
    allJson {
      edges {
        node {
          id
          fields {
            slug
          }
          components {
            type
            content
          }
        }
      }
    }

    allNewsJson {
      edges {
        node {
          id
          date
          href
          snippet
        }
      }
    }

    allDownloadsJson {
      edges {
        node {
          id
          title
          href
          description
        }
      }
    }

    ecsSitewideMessages {
      content {
        messageType
        siteMessageEnabled
        siteMessages {
          messageLocation
          shortCopy
        }
      }
    }

    allEcsNav {
      edges {
        node {
          content
        }
      }
    }
    ecsNav {
      content
    }
    allEcsPage {
      nodes {
        slug
        content
        id
      }
    }
  }
`
export default function Content(props) {
  const pageInformation = <PageInformation title="Search Results Page" />
  const headData = {
    pageInformation: pageInformation,
    htmlAttributes: { lang: "en-us" },
    canonicalUrl: props.location.href,
  }
  const {
    allNewsJson,
    allDownloadsJson,
    allJson,
    allEcsNav,
    allEcsPage,
    ecsNav,
    ecsSitewideMessages,
  } = props.data

  const allDownloads = allDownloadsJson.edges.map((edge) => edge.node)
  const allNews = allNewsJson.edges.map((edge) => edge.node)
  const allData = allJson.edges.map((edge) => edge.node)
  const allNavigation = allEcsNav.edges.map((edge) => edge.node)
  const noResultsLink = allNavigation[0].content.items.map((menuItem) => {
    return {
      title: menuItem.linkText,
      href:
        menuItem.linkHref !== "#"
          ? menuItem.linkHref
          : menuItem.dcrs[0].items[0].linkHref,
    }
  })

  const filterComponents = [
    "accordion",
    "footnote",
    "intro",
    "promo",
    "tab",
    "valueDrivers",
  ]

  let data = [...allDownloads, ...allNews, ...allData]
  let filteredComponents
  let pageData = data.map((dataItem) => {
    if (dataItem.components?.length) {
      filteredComponents = dataItem.components.filter((components) =>
        filterComponents.includes(components.type)
      )
      dataItem.content = JSON.stringify(filteredComponents)
      dataItem.id = nanoid()
      dataItem.slug = `/${dataItem.fields.slug}`
    }
    return dataItem
  })

  const allEcs = allEcsPage.nodes.filter(
    (page) => !page.slug.startsWith("hybrid/")
  )
  const allDownloadsData = []
  const updateDownloadsData = (document, allDownloadsData) => {
    return document.split("-").forEach((link) => {
      if (
        link.endsWith(".pdf") ||
        link.endsWith(".doc") ||
        link.endsWith(".docx")
      ) {
        allDownloadsData.push({
          id: nanoid(),
          slug: link,
          href: link,
          title: decodeURI(link.split("/").slice(-1)[0]),
          description: decodeURI(link.split("/").slice(-1)[0]),
        })
      }
    })
  }
  const ecsPageData = allEcs.map((page) => {
    const content = filterPageContent(page)

    const finalContent = content
      .filter((value) => Object.keys(value).length !== 0)
      .reduce(function (acc, x) {
        for (let key in x) acc[key] = x[key]
        return acc
      }, {})

    const {
      contentRoute,
      disclaimerText,
      applicationName,
      acceptLanguage,
      btnStyle,
      contentAlignment,
      desktopHeroType,
      imageWrapped,
      linkTarget,
      mobileImageSize,
      linkType,
      layout,
      modelName,
      name,
      pageType,
      pdfText,
      textStyle,
      type,
      useHeroLink,
      hrefLangID,
      langURL,
      imageMobileSrc,
      imageSrc,
      imageAlt,
      rowSeparator,
      readMoreLess,
      pdfHref: pdf,
      ...finalContentforSearch
    } = finalContent

    if (pdf) {
      updateDownloadsData(pdf, allDownloadsData)
    }

    const pageInformation =
      getComponent(
        page.content.components.find(
          (component) => component.name === "Page_Information"
        )
      ) ?? {}

    return {
      id: page.id,
      slug: `/${page.slug}`,
      title: pageInformation.pageTitle,
      description: pageInformation.pageDescription || "",
      content: JSON.stringify(Object.values(finalContentforSearch)).replace(
        /<[^>]*>?/gm,
        ""
      ),
    }
  })
  const { linkHref: downloads } = flattenObject(ecsNav.content.items)
  updateDownloadsData(downloads, allDownloadsData)

  const finalData = [...pageData, ...ecsPageData, ...allDownloadsData] // Merging both static and ecs/cms data

  const sitewideMessages = getSitewideMessages(ecsSitewideMessages?.content)
  return (
    <BaseLayout head={headData} sitewideMessages={sitewideMessages}>
      <section id="content-link" role="main" tabIndex="-1">
        <ClientSearch data={finalData} noResultsLink={noResultsLink} />
      </section>
    </BaseLayout>
  )
}

Content.propTypes = {
  data: shape({
    json: object,
    allNewsJson: object,
    allDownloadsJson: object,
    allJson: object,
    allEcsNav: shape({
      edges: arrayOf(
        shape({
          node: shape({
            content: shape({
              items: arrayOf(
                shape({
                  href: string,
                  linkText: string,
                  linkId: string,
                  linkHref: string,
                  linkTarget: oneOf(["_self", "_blank"]),
                  linkVisibility: oneOf(["_desktop", "_mobile", "_both"]),
                  dcrs: arrayOf(
                    shape({
                      items: arrayOf(
                        shape({
                          linkText: string,
                          linkId: string,
                          linkHref: string,
                          linkTarget: oneOf(["_self", "_blank"]),
                          linkVisibility: oneOf([
                            "_desktop",
                            "_mobile",
                            "_both",
                          ]),
                        })
                      ),
                    })
                  ),
                  metadata: object,
                })
              ),
            }),
          }),
        })
      ),
    }),
    ecsNav: object,
    allEcsPage: object,
  }),
  location: shape({
    href: string,
  }),
}
